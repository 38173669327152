import React from 'react';
import styles from "../home2Banner.module.css"
import { Link } from "gatsby"
import newsStyles from "../../components/Investor/bannerNews.module.css"
import { Icon } from 'react-icons-kit'
import {bookmark} from 'react-icons-kit/fa/bookmark'
import {heart} from 'react-icons-kit/fa/heart'
import {comment} from 'react-icons-kit/fa/comment'
import { isNullUndefined, getMomentDate, addDisclaimersToCmsLinks, richTextOptions } from "../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"

const HomeBanner = (props) => {

  const siteEdges = props.queryData.allSitePage.edges.filter(edge => edge.node.path.startsWith("/Investor"))

  const newsListPath = siteEdges.filter(edge => {
      let pathFromEdge = edge.node.path;
      const possiblePaths = ["/Investor/news"];
      for(let path of possiblePaths){
          if(pathFromEdge === path){
              return pathFromEdge;
          }
      }
  })[0]


  let {edges: newsItemEdges} = props.queryData.allContentfulNewsItemTemplate;

  if(isNullUndefined(newsItemEdges)){
    newsItemEdges = []
  }

  const sortByLatestYear = (a, b) => {
    if(parseInt(a) > parseInt(b)){
      return -1;
    }
    if(parseInt(a) < parseInt(b)){
      return 1
    }
    return 0;
  }

  const sortItemsByLatestDate = (a, b) => {
    if(isNullUndefined(a) || isNullUndefined(b)){
      return 0;
    }
    if ( (new Date(a.node.datePublished)).getTime() < (new Date(b.node.datePublished)).getTime() ){
        return 1;
    }
    if ( (new Date(a.node.datePublished)).getTime() > (new Date(b.node.datePublished)).getTime() ){
        return -1;
    }
    return 0;
  }

  const getLatestNewsItems = (count) => {
    let years = [];
    for(let edge of newsItemEdges){
      if(!years.includes(edge.node.year)){
        years.push(edge.node.year);
      }
    }
    let currentYear = years.sort(sortByLatestYear)[0];

    const latestNews = newsItemEdges.filter(item => item.node.year === currentYear).sort(sortItemsByLatestDate).map((item, index) => {
      let child = item.node;
      let datePublished = getMomentDate(child.datePublished);
      return (
        <div className={`${newsStyles.news_card } ${newsStyles.card} ` } >
        <div className={newsStyles.wrapper}>
        <div className={newsStyles.date}>
        <span className={newsStyles.day}>{datePublished.format("DD")}</span>
        <span className={newsStyles.month}>{datePublished.format("MMM")}</span>
        <span className={newsStyles.year}>{datePublished.format("YYYY")}</span>
        </div>
        <div className={`${newsStyles.date} ${newsStyles.view_all}`}>
           <Link to={isNullUndefined(newsListPath) ? "#": newsListPath.node.path}><button className={ `${newsStyles.custom_btn } ${newsStyles.bordered_btn}`}><span>View all</span></button></Link> 
        </div>
        <div className={newsStyles.data}>
        <div className={newsStyles.content}>
          <h1 className={newsStyles.title}><a href="#">{child.title}</a></h1>
        </div>
        <input type="checkbox" id="show-menu2" />
        <ul className={newsStyles.menu_content}>
          <li>
            <Link> <Icon size={30} icon={bookmark}/> <span>5</span></Link>
          </li>
          <li>
         
          <Link>  <Icon size={30} icon={comment}/> <span>2</span></Link>
              </li>
          <li>
              <Link> <Icon size={30} icon={heart}/> <span>8</span></Link>
        </li>
        </ul>
        </div>
        </div>
        </div>
      )
    })
    return latestNews.slice(0, count);
  }

  const {bannerSection} = props.queryData.contentfulHomeTemplate || {};

  let bannerSectionRichText = documentToReactComponents(isNullUndefined(bannerSection) ? bannerSection : bannerSection.json, richTextOptions);

  let bannerSectionHtml = ReactDOMServer.renderToStaticMarkup(bannerSectionRichText);

return ( 
    <div>
        <div className={`${styles.banner} ${styles.investor_banner}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                       <div className={styles.banner_content}>
                        {ReactHtmlParser(bannerSectionHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})}
                        {/* <div className={`${styles.shareholder_card} col-12 col-sm-12 col-md-8`}>
                          <h3 className="m-0 mb-3">Share price</h3>
                          <iframe src="https://secure.flife.de/ir/data/diurnal/ticker.php"></iframe>
                        </div> */}
                       </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                    <div className={newsStyles.banner_news}>
                      {getLatestNewsItems(1)}
                    </div>
                  </div>
                   
                </div>
            </div>
        </div>
    </div>
 );
}
 
export default HomeBanner;