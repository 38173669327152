import React ,{ useState }from 'react';
import {graphql} from "gatsby"
import queryString from 'query-string';
import Banner from '../../components/Investor/banner';
import Mission from "../../components/Investor/mission"
import HomeNews from '../../components/Investor/news';
// import Vision from '../../components/Investor/ourVision';
import Layout from '../../components/Investor/layout';
import SEO from "../../components/seo"
import { ValidateRoute, ShowDisclaimerForDynamicPages } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"
import { getDefaultMainLandingDisclaimerPopupConfigsInvestors } from "../../util/configs"
import MainLandingDisclaimerPopup from "../../components/prompts/disclaimers/main-landing/disclaimer-popup"
export const pageQueryHomeTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulHomeTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                spaceId
                slug
                bannerSection {
                    json
                }
                missionSection {
                    json
                }
                bottomExcerptText {
                    json
                }
                bottomExcerptButtonUrl
                bottomExcerptImage {
                    file {
                        url
                    }
                }
        }
        allContentfulNewsItemTemplate {
            edges {
              node {
              author
              datePublished
              description {
                content {
                  content {
                    value
                  }
                }
              }
              slug
              title
              year
            }
            }
        }
        allSitePage {
            edges {
                node {
                path
                }
            }
        }
    } 
`

const HomeTemplatePageInvestor = (props) => {
  const queryData = props.data;

    const [directLinkPopUpConfig, setDirectLinkPopUpConfig] = useState(null);

  let [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {

      let {veeva} = queryString.parse(props.location.search || "");

      if(!veeva){
        setAnimate(true);
      }
      ShowDisclaimerForDynamicPages("Investor-home", getDefaultMainLandingDisclaimerPopupConfigsInvestors(null, true), setDirectLinkPopUpConfig);
      localStorage.setItem("Investor-home", JSON.stringify({ disclaimerShown: true }));

  }, []);

  return (        
    <TemplateWrapper>
        <div className={animate ? "" : "overwrite_animations"}>
        <Layout>
            <SEO title={`Home | Investor or Media Relations`} />
            <Banner queryData={queryData} animate={animate} /> 
            <Mission queryData={queryData} animate={animate} />
            <HomeNews queryData={queryData} animate={animate} />
            {directLinkPopUpConfig && directLinkPopUpConfig.show && <MainLandingDisclaimerPopup popupConfig={directLinkPopUpConfig} hide={() => setDirectLinkPopUpConfig({ directLinkPopUpConfig: null })} />}
        </Layout>
    </div>
    </TemplateWrapper>
   
    );
}
 
export default HomeTemplatePageInvestor;