import React from 'react';
import styles from "../home2news.module.css"
import { Icon } from 'react-icons-kit'
import {bookmark} from 'react-icons-kit/fa/bookmark'
import {heart} from 'react-icons-kit/fa/heart'
import {comment} from 'react-icons-kit/fa/comment'
import { graphql, useStaticQuery, Link } from 'gatsby';
import {getMomentDate, isNullUndefined} from "../../util"
 
const HomeNews = (props) => {

  const { allContentfulNewsItemTemplate: {edges} } = props.queryData;

  if(isNullUndefined(edges)){
    edges = []
  }

  const sortByLatestYear = (a, b) => {
    if(parseInt(a) > parseInt(b)){
      return -1;
    }
    if(parseInt(a) < parseInt(b)){
      return 1
    }
    return 0;
  }

  const sortItemsByLatestDate = (a, b) => {
    if(isNullUndefined(a) || isNullUndefined(b)){
      return 0;
    }
    if ( (new Date(a.node.datePublished)).getTime() < (new Date(b.node.datePublished)).getTime() ){
        return 1;
    }
    if ( (new Date(a.node.datePublished)).getTime() > (new Date(b.node.datePublished)).getTime() ){
        return -1;
    }
    return 0;
  }

  const getLatestNewsItems = (count) => {
    let years = [];
    for(let edge of edges){
      if(!years.includes(edge.node.year)){
        years.push(edge.node.year);
      }
    }
    let currentYear = years.sort(sortByLatestYear)[0];

    const latestNews = edges.filter(item => item.node.year === currentYear).sort(sortItemsByLatestDate).map((item, index) => {
      let child = item.node;
      let datePublished = getMomentDate(child.datePublished);
      let description = child?.description?.content;
      return (
          <div key={index} className={`${styles.news_card } ${styles.card} col-12 col-sm-12 col-md-6 col-lg-4` } >
            <Link to={`/Investor/news/${child.slug}`}>
        <div className={styles.wrapper}>
        <div className={styles.date}>
          <span className={styles.day}>{datePublished.format("DD")}</span>
          <span className={styles.month}>{datePublished.format("MMM")}</span>
          <span className={styles.year}>{datePublished.format("YYYY")}</span>
        </div>
        <div className={styles.data}>
          <div className={styles.content}>
            <h1 className={styles.title}><span>{child.title}</span></h1>
            <p className={styles.text}>{description?.reduce((acc, item) => {
            let descriptionContent = item.content || [];
                      for(let text of descriptionContent){
                          acc += `${text.value} `;
                      }
                      return acc
                  },"")}</p>
            <label htmlFor="show-menu2" className={styles.menu_button}><span></span></label>
          </div>
          <input type="checkbox" id="show-menu2" />
          <ul className={styles.menu_content}>
            <li>
            
              <Link> <Icon size={30} icon={bookmark}/> <span>5</span></Link>
            </li>
            <li>
          
            <Link>  <Icon size={30} icon={comment}/> <span>2</span></Link>
                </li>
            <li>
                <Link> <Icon size={30} icon={heart}/> <span>8</span></Link>
          </li>
          </ul>
        </div>
        </div>
        </Link>
        </div>
      )
    })
    return latestNews.slice(0, count);
  }

  return ( 
    <div id="news" className={styles.news_wrap}>
           <div className="container">
          <div className="row">
              <div className={`${styles.news_heading} col-12`}>
            <h1>Latest News</h1>
              </div>
          </div>
         <div className="row pb-5">
              
          {getLatestNewsItems(6)} 

  
          </div>

      </div>
  
    </div>
  );
}

export default HomeNews;
