import React from 'react';
import styles from "../home2Banner.module.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { isNullUndefined, addDisclaimersToCmsLinks, richTextOptions } from "../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"

const HomeMission = (props) => {

  const {missionSection} = props.queryData.contentfulHomeTemplate || {};

  let missionSectionRichText = documentToReactComponents(isNullUndefined(missionSection) ? missionSection : missionSection.json, richTextOptions);

  let missionSectionHtml = ReactDOMServer.renderToStaticMarkup(missionSectionRichText);

return ( 
    <div>
        <div className={styles.homeMission}>
            <div className="container text-center">
                {props.animate ? (
                    <ScrollAnimation animateIn="fadeInUp">
                        {ReactHtmlParser(missionSectionHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})}
                    </ScrollAnimation>
                ) : (
                    ReactHtmlParser(missionSectionHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})
                )}
            </div>
           </div>
    </div>
 );
}
 
export default HomeMission;